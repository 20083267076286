'use client';

import Script from 'next/script';
import {
  getTransactionSurvey,
  setTransactionSurveyCompleted,
} from '@api/caerus';
import { LiraRunAdvScript } from '@sbt-web/adv';
import { getPublicUser } from '@sbt-web/auth';
import { HTTPStatusCode } from '@sbt-web/networking';
import { presencePing, type PresencePingParams } from '@sbt-web/presence-ping';
import React, { useEffect } from 'react';
import { viewSurveyTracking, viewTracking } from '../../helpers/PulseTracking';
import { AdvGoogleBox } from '../AdvGoogleBox';
import { AdvTopBanner } from '../AdvTopBanner';
import { AppWidget } from '../AppWidget';
import { CategoriesLinks } from '../FindOutMore';
import { Logo } from '../Logo';
import { PromoteWidgetWrapper } from '../PromoteWidgetWrapper';
import { SearchBox } from '../SearchBox';
import {
  default as MagazineWidget,
  MagazineWidgetFeatures,
} from '../MagazineWidget';
import TuttoSubitoWidget, {
  TuttoSubitoWidgetFeatures,
} from '../../components/TuttoSubito';
import SellWidget, { SellWidgetFeatures } from '../../components/SellWidget';
import SafetyTips, {
  SafetyTipsWidgetFeatures,
} from '../../components/SafetyTips';

import '../../index.scss';
import { advFallbackConfigMsite } from '../../helpers/adv/lira/fallbackConfig/msiteConfig';
import { advFallbackConfigDesktop } from '../../helpers/adv/lira/fallbackConfig/desktopConfig';
import { RecommenderWidget } from '../RecommenderWidget';

const initPresencePing = (userId: string) => {
  const presenceParams: PresencePingParams = {
    presenceApiUrl: process.env.NEXT_PUBLIC_TRUST_PRESENCE_BASE,
    profileApiUrl: process.env.NEXT_PUBLIC_TRUST_PROFILE_BASE,
    hadesPublicBase: process.env.NEXT_PUBLIC_HADES_BASE,
    userId,
    intervalTime: 3000,
    checkSetting: true,
  };

  presencePing(presenceParams);
};

export type DynamicTouchpoints = {
  magazineWidget: MagazineWidgetFeatures | null;
  sellWidget: SellWidgetFeatures | null;
  safetyTipsWidget: SafetyTipsWidgetFeatures | null;
  tuttoSubitoWidget: TuttoSubitoWidgetFeatures | null;
};
type Props = {
  dynamicTouchpoints: DynamicTouchpoints;
  recommendedWidgetEnabled: boolean;
};
export default function Home({
  dynamicTouchpoints,
  recommendedWidgetEnabled,
}: Readonly<Props>) {
  const user = getPublicUser();

  useEffect(() => {
    if (user?.id) {
      initPresencePing(user?.id);
    }
  }, [user?.id]);

  useEffect(() => {
    viewTracking();
  }, []);

  useEffect(() => {
    if (!user) return;

    const notifySurveyCompleted = async () => {
      try {
        const result = await setTransactionSurveyCompleted();
        if (result.status !== HTTPStatusCode.NoContent) {
          console.error(`Cannot notify survey accepted: ${result.info}`);
        }
      } catch (e) {
        console.error(`Cannot notify survey accepted: ${e}`);
      }
    };
    const showTransactionSurvey = () => {
      async function showSurvey() {
        try {
          const result = await getTransactionSurvey();
          if (result.status === HTTPStatusCode.NoContent) {
            viewSurveyTracking();
            window.KAMPYLE_ONSITE_SDK.showForm('23977');
            notifySurveyCompleted();
          }
        } catch {
          console.warn('Cannot check for transaction surveys');
        }
      }
      showSurvey();
    };

    const loadMedalliaSDK = () => {
      if (window.Didomi.getUserConsentStatusForVendor('c:medallia-ihzryFLY')) {
        const script = document.createElement('script');
        script.src = 'https://nebula-cdn.kampyle.com/we/293639/onsite/embed.js';
        script.async = true;
        document.head.appendChild(script);
      }
    };
    window.addEventListener('neb_OnsiteLoaded', showTransactionSurvey);
    window.didomiOnReady = window.didomiOnReady || [];
    window.didomiOnReady.push(loadMedalliaSDK);

    return () => {
      window.removeEventListener('neb_OnsiteLoaded', showTransactionSurvey);
    };
  }, [user]);

  return (
    <>
      <LiraRunAdvScript
        NextJsScriptComponent={Script}
        vertical="subito"
        pageType="home"
        env={process.env.NEXT_PUBLIC_INTERNAL_ENVIRONMENT}
        fallbackHebeConfig={{
          desktop: advFallbackConfigDesktop,
          msite: advFallbackConfigMsite,
        }}
      />
      <div className="standard-container">
        <Logo />
        <SearchBox />
      </div>

      <div className="standard-container">
        <PromoteWidgetWrapper />
      </div>

      <AdvTopBanner />

      <div className="standard-container standard-container--with-bottom-padding dynamic-atf-container">
        {recommendedWidgetEnabled && <RecommenderWidget />}
        <TuttoSubitoWidget features={dynamicTouchpoints.tuttoSubitoWidget} />
        <SellWidget features={dynamicTouchpoints.sellWidget} />
        <MagazineWidget features={dynamicTouchpoints.magazineWidget} />
        <AdvGoogleBox />
      </div>

      <SafetyTips features={dynamicTouchpoints.safetyTipsWidget} />

      <div className="standard-container">
        <AppWidget />
        <CategoriesLinks />
      </div>
    </>
  );
}
